import React from "react"
import styled from "styled-components"
import SectionWrapper from "./section-wrapper"
import Button from "./button"
import ContactForm from "./contact-form"
import Map from "./map"

const Wrapper = styled(SectionWrapper)`
  margin-bottom: 30px;
  max-width: ${props => props.theme.maxWidth};
  overflow: hidden;
`

const Grid = styled.div`
  @media (min-width: ${props => props.theme.maxWidth}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

const Column = styled.div`
  width: 100%;
  max-width: 460px;
  margin: 0 auto 30px;
`

const LinkWrapper = styled.div`
  p {
    margin-top: 10px;
  }

  margin-bottom: 10px;
`

const FormLabel = styled.p`
  margin: 30px 0;
  text-align: center;
`

const Address = styled.p`
  font-size: ${props => props.theme.fontSizes.big};
  color: ${props => props.theme.colors.primary};
  font-weight: bold;
`

const OpeningHoursList = styled.ul`
  list-style: none;
  padding-left: 1rem;
  margin-bottom: 1rem;

  li {
    display: flex;
    padding: 0.25rem 0;
  }
  span:first-child {
    width: 75px;
  }
`

const ContactSection = () => (
  <Wrapper title="Umów wizytę" sectionId="contact">
    <Grid>
      <Column>
        <LinkWrapper>
          <p>Napisz:</p>
          <Button
            as={"a"}
            secondary
            href={`mailto:psychoterapiazaborowska@gmail.com`}
            trackingId="Contact - Email"
          >
            psychoterapiazaborowska@gmail.com
          </Button>
        </LinkWrapper>
        <LinkWrapper>
          <p>Zadzwoń:</p>
          <Button
            as={"a"}
            secondary
            href={`tel:+48537578459`}
            trackingId="Contact - Phone"
          >
            +48 537 578 459
          </Button>
        </LinkWrapper>
        <FormLabel>lub skorzystaj z poniższego formularza:</FormLabel>
        <ContactForm />
      </Column>
      <Column>
        <LinkWrapper>
          <p>Adres:</p>
          <Address>Kraków, ul. Kalwaryjska 14</Address>
          <OpeningHoursList>
            <li>
              <span>Wtorek</span>14:00 - 21:00
            </li>
            <li>
              <span>Środa</span>14:00 - 21:00
            </li>
          </OpeningHoursList>
        </LinkWrapper>
        <Map />
      </Column>
    </Grid>
  </Wrapper>
)

export default ContactSection
