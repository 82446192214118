import React from "react"
import styled from "styled-components"
import SectionWrapper from "./section-wrapper"

const List = styled.ul`
  list-style: none;

  @media (min-width: ${props => props.theme.breakPoints.iPad}) {
    columns: 2;
    column-gap: 60px;
  }
`

const ListItem = styled.li`
  padding: 15px;

  &::before {
    content: "\\2022";
    font-size: 1.2em;
    color: ${props => props.theme.colors.primary};
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    margin-top: 1px;
  }
`

const HelpSection = () => (
  <SectionWrapper title="Zakres pomocy" sectionId="help">
    <p>Pracuję z osobami:</p>
    <List>
      <ListItem>
        Odczuwającymi smutek, złość lub frustrację, których źródeł nie potrafią
        określić,
      </ListItem>
      <ListItem>
        Mającymi obniżone poczucie własnej wartości, doświadczającymi poczucia
        winy lub lęku,
      </ListItem>
      <ListItem>
        Zmagającymi się z problemami związanymi z obszarem pracy oraz nauki,
      </ListItem>
      <ListItem>Doświadczającymi zaburzeń nastroju,</ListItem>
      <ListItem>
        Podczas kryzysu życiowego (Utrata, choroba, duże zmiany, wejście w nową
        rolę),
      </ListItem>
      <ListItem>
        Obserwującymi u siebie powtarzające się schematy zachowania, które
        wpływają negatywnie na bliskie związki, życie społeczne i zawodowe,
      </ListItem>
      <ListItem>
        Doświadczającymi przykrych objawów psychosomatycznych,
      </ListItem>
      <ListItem>Z diagnozą lub podejrzeniem zaburzenia osobowości.</ListItem>
    </List>
  </SectionWrapper>
)

export default HelpSection
