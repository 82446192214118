import React from "react"
import styled from "styled-components"
import Button from "./button"

const Form = styled.form`
  display: flex;
  flex-direction: column;
`
const Input = styled.input`
  width: 100%;
  padding: 10px;
  outline: none;
  border: 1px solid ${props => props.theme.colors.content};
  margin-top: 10px;
  margin-bottom: 20px;

  :focus {
    border: 1px solid ${props => props.theme.colors.primary};
    border-bottom: 3px solid ${props => props.theme.colors.primary};
    margin-bottom: 18px;
  }
`

const SubmitButton = styled(Button)`
  align-self: flex-end;
`

const ContactForm = props => (
  <Form name="Contact Form" method="POST" data-netlify="true" {...props}>
    <input type="hidden" name="form-name" value="Contact Form" />
    <label htmlFor="nameInput">
      Imię i nazwisko:
      <Input
        type="text"
        placeholder="Jan Kowalski"
        id="nameInput"
        name="nameInput"
        required
      />
    </label>
    <label htmlFor="emailInput">
      Email:
      <Input
        type="email"
        placeholder="jankowalski@email.com"
        id="emailInput"
        name="emailInput"
        required
      />
    </label>
    <label htmlFor="messageInput">
      Wiadomość:
      <Input
        as="textarea"
        type="textarea"
        placeholder="Wpisz treść wiadomości..."
        id="messageInput"
        name="messageInput"
        required
      />
    </label>
    <SubmitButton type="submit" trackingId="Contact - Submit">
      Wyślij
    </SubmitButton>
  </Form>
)

export default ContactForm
