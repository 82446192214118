import React from "react"

import SEO from "../components/seo"
import MainLayout from "../layouts/main-layout"
import HeroSection from "../components/hero-section"
import HelpSection from "../components/help-section"
import ContactSection from "../components/contact-section"

const IndexPage = ({ data }) => {
  const images = data.allFile.edges.reduce((acc, { node }) => {
    acc[node.name] = node.childImageSharp.fluid

    return acc
  }, {})

  return (
    <MainLayout>
      <SEO title="Strona główna" />
      <HeroSection avatar={images.avatar} hero={images.hero} />
      <HelpSection />
      <ContactSection />
    </MainLayout>
  )
}

export const query = graphql`
  query IndexPage {
    allFile(filter: { extension: { regex: "/(jpg)/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(
              quality: 100
              maxWidth: 3024
              srcSetBreakpoints: [150, 420, 768, 1024, 1280, 1920, 3024]
            ) {
              aspectRatio
              sizes
              srcWebp
              tracedSVG
              srcSetWebp
            }
          }
        }
      }
    }
  }
`

export default IndexPage
