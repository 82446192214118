import React from "react"
import styled from "styled-components"
import Title from "./title"
import Button from "./button"
import Image from "gatsby-image"
import { Link } from "gatsby"
import Avatar from "./avatar"

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;

  @media (min-width: ${props => props.theme.breakPoints.iPad}) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px;
  }
`

const HeroCard = styled.div`
  background-color: ${props => props.theme.colors.backgroundTransparent};
  padding: 0 20px;
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;

  @media (min-width: ${props => props.theme.breakPoints.iPad}) {
    display: flex;
    flex-direction: column;
    padding: 30px 60px;
  }
`

const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  margin-top: 30px;

  a:first-child {
    margin-bottom: 20px;
  }

  @media (min-width: ${props => props.theme.breakPoints.iPad}) {
    flex-direction: row;

    a:first-child {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
`

const StyledImage = styled(Image)`
  height: 100%;
`

const StyledTitle = styled(Title)`
  padding: 20px 0;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const Grid = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${props => props.theme.breakPoints.iPad}) {
    flex-direction: row;
    align-items: center;
  }
`

const LeftColumn = styled.div`
  max-width: 500px;
  margin: 0 auto;
`

const StyledAvatar = styled(Avatar)`
  margin: 20px auto 0px;

  @media (min-width: ${props => props.theme.breakPoints.iPad}) {
    margin: -20px auto 0;
  }
`

const HeroSection = ({ hero, avatar }) => (
  <Wrapper>
    <HeroCard>
      <ImageWrapper>
        <StyledImage fluid={hero} />
      </ImageWrapper>
      <Grid>
        <LeftColumn>
          <StyledTitle />
          <p>
            Oferuję konsultacje psychologiczne oraz psychoterapię indywidualną
            osób dorosłych.
          </p>
          <p>
            Podczas kontaktu psychoterapeutycznego stawiam na autentyczność w
            relacji i zapewnienie poczucia bezpieczeństwa, abyśmy wspólnie mogli
            pracować nad poprawą funkcjonowania.
          </p>
        </LeftColumn>
        <StyledAvatar image={avatar} />
      </Grid>
      <ButtonBox>
        <StyledLink to="#help">
          <Button trackingId="Hero - Who I Help">Zakres pomocy</Button>
        </StyledLink>
        <StyledLink to="/about">
          <Button secondary trackingId="Hero - About Me">
            O mnie
          </Button>
        </StyledLink>
      </ButtonBox>
    </HeroCard>
  </Wrapper>
)

export default HeroSection
