import React from "react"
import styled from "styled-components"

const apiKey = process.env.GATSBY_GOOGLE_API_KEY

const IFrame = styled.iframe`
  width: 100%;
  height: 400px;
`

const Map = props => {
  return (
    <div {...props}>
      <IFrame
        frameBorder="0"
        style={{ border: 0 }}
        src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}
          &q=place_id:ChIJ95lWzlxbFkcRoF-8mTsOcnM&language=PL`}
        allowFullScreen
      ></IFrame>
    </div>
  )
}

export default Map
